<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <!-- <vue-element-loading v-show="isLoader" /> -->
    <main>
      <div class="main_content align-items-center">
        <div class="row mb_40">
          <div class="col-md-12 col-lg-6">
            <div class="heading">
              <h2>Send Notification</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="table_search">
              <div class="d-flex align-items-center"></div>
            </div>
          </div>
        </div>

        <form method="post" @submit.prevent="sendEmailTemplate" data-vv-scope="sendEmailTemplateForm">
          <div class="card card_wrap">
            <div class="row">
              <div class="col-md-12 col-xl-6">
                <div class="form_size_box form-group">
                  <label for="" class="form-label">Client List</label>
                  <v-select :reduce="(client_list) => client_list.id" :options="client_list" label="name"
                    placeholder="Select Client" name="Client" v-model="template_data.customer_id" v-validate="'required'"
                    @input="getUsersBySearch('clientchange')" />
                  <small class="error text-danger" v-show="errors.has('sendEmailTemplateForm.Client')">
                    {{ errors.first("sendEmailTemplateForm.Client") }}
                  </small>
                  <div class="form_size_box form-group mt_24">
                    <label for="" class="form-label">Email Template</label>
                    <v-select :options="email_template_list" label="template_name" placeholder="Email Template"
                      name="Template" v-model="template_data.email_template_id" @input="getTemplateDetails()" />
                  </div>

                  <div class="form-group">
                    <label for="" class="form-label">
                      Subject
                    </label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                      placeholder="Email Subject" name="Subject" v-model="template_data.email_template_subject"
                      v-validate="'required'" />
                    <small class="error text-danger" v-show="errors.has('sendEmailTemplateForm.Subject')">
                      {{ errors.first("sendEmailTemplateForm.Subject") }}
                    </small>
                  </div>

                  <div>
                    <label for="" class="form-label">
                      Email Body
                    </label>
                    <ckeditor type="classic" :editor="editor" v-model="template_data.editorData" :config="editorConfig"
                      v-validate="'required'" name="email body content"
                      @blur="onLostFocusEditor('sendEmailTemplateForm.email body content')">
                    </ckeditor>
                    <small class="error text-danger" v-show="errors.has('sendEmailTemplateForm.email body content')
                      ">
                      {{
                        errors.first("sendEmailTemplateForm.email body content")
                      }}
                    </small>
                  </div>
                  <div class="suggestion_sendnote">
                    <p>
                      <strong>{user firstname}</strong> used for User's
                      firstname.
                    </p>
                    <p>
                      <strong>{formlink self assessment}</strong> used for
                      Self-Assessment link.
                    </p>
                    <p>
                      <strong>{formlink ergo eval}</strong> used for User's Ergo
                      Eval link.
                    </p>
                    <p>
                      <strong>{formlink schedule request}</strong> used for
                      schedule request
                    </p>
                    <p>
                      <strong>{formlink self assesment follow up}</strong> used
                      for Self-Assessment follow-up link.
                    </p>
                    <p>
                      <strong>{formlink ergo eval follow up}</strong> used for
                      Ergo Eval follow-up link.
                    </p>
                    <p>
                      <strong>{add client name}</strong> used for client name.
                    </p>
                    <p>
                      <strong>{formlink self assessment follow up}</strong> used
                      for Self-Assessment follow-up link.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-xl-6">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <label for="" class="form-label">Users</label>
                    <div class="">
                      <div class="row justify-content-end">
                        <div class="col-md-12 col-xl-10 col-xxl-7"></div>
                      </div>
                      <div class="row align-items-center dataTables_wrapper no-footer mb-0" id="datatable_wrapper" v-if="data_length > 0 || listConfig.clientId">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                          <div id="datatable_filter" class="dataTables_filter">
                            <label><input type="search" class="" placeholder="Search" aria-controls="datatable" v-model="listConfig.search_string" @keydown.enter.prevent="getUsersBySearch" />
                              <a v-if="listConfig.search_string" href="javascript:void(0)" class="search_icon" @click="getUsersBySearch">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#7d8d9e" class="bi bi-search"
                                  viewBox="0 0 16 16">
                                  <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                              </a>
                              <a href="javascript:void(0)" v-if="listConfig.search_string"
                              @click="listConfig.search_string = null; getUsersBySearch()" class="crossicon" style="font-size: 13px;">
                                X
                              </a>
                            </label>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                          <div class="form-group for_ipad">
                            <v-select @input="getUsersBySearch" :reduce="(regionList) => regionList.id" :options="regionList" label="name" placeholder="By Region" name="Region" v-model="listConfig.regionBy"
                             browserAutocomplete="false" autocomplete="off" />
                            <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                              {{ errors.first("addUserForm.Region") }}
                            </small>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12">
                          <div class="dataTables_length" id="datatable_length">
                            <label>Show
                              <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage" @change="getUsersBySearch">
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option>500</option>
                              </select>
                              entries</label>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive mxheight-250 mob-table no_scroll cust_scroll">
                        <table class="table" style="min-height:43px">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div class="
                                    form_check_box
                                    d-flex
                                    align-items-center
                                  " v-if="data_length > 0">
                                  <input type="checkbox" value="" aria-label="..." id="flexCheckDefault"
                                    v-model="isCheckAll" class="form-check-input ms-0 usercheckbox"
                                    @click="userAllcheck" />
                                  <label for="flexCheckDefault" class="">
                                  </label>
                                </div>
                              </th>
                              <th class="sort">
                                <SortIcons :lable="'Name'" :listConfig="listConfig" :sortBy="'first_name'"
                                  @sorting="sorting($event)" />
                              </th>
                              <th class="sort">
                                <SortIcons :lable="'Email'" :listConfig="listConfig" :sortBy="'email'"
                                  @sorting="sorting($event)" />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(user, indx) in user_email_list" :key="indx">
                              <td>
                                <div class="
                                    form_check_box
                                    d-flex
                                    align-items-center
                                  ">
                                  <input type="checkbox" :value="user.id" class="form-check-input ms-0 usercheckbox"
                                    v-model="template_data.selectedUser" v-validate="'required'" data-vv-as="user email"
                                    name="selectedUser" />

                                  <label for="flexCheckDefault" class="">
                                  </label>
                                </div>
                              </td>

                              <td>
                                <p>
                                  {{ user.first_name }} {{ user.last_name }}
                                </p>
                              </td>
                              <td>
                                <p class="email_tag">
                                  <a href="mailto:Lea.Sangalang@hpsm.org" class="site_p">{{ user.email }}</a>
                                </p>
                              </td>
                            </tr>
                            <tr v-if="data_length == 0">
                              <td colspan="3" class="text-center">
                                No Records found
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="row" style="justify-content: space-between;"> 
                        <p class="text-center col-xxl-3 col-12 p-0" v-if="data_length" style="line-height:30px;vertical-align:middle;font-size: 14px;">{{ positionText }}</p>
                      <VPagination v-if="data_length" class="dataTables_paginate col-xxl-9 col-12 p-0 notify-pagination" :totalPages="pages.length"
                        :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
                      </div>
                      
                      <small class="error text-danger" v-show="!isSearchAction && errors.has('sendEmailTemplateForm.selectedUser')
                        ">
                        {{ errors.first("sendEmailTemplateForm.selectedUser") }}
                      </small>
                      <small class="error text-danger">
                        {{ user_email_error }}
                      </small>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="formFile" class="form-label">Add Image and/or Upload Document</label>
                  <div class="wrapper form-control choose_file">
                    <div class="file-upload" style="text-align: center">
                      <input type="file" ref="attachmentref" @change="onFileChange($event, 'attachment')"
                        placeholder="attachment.png"
                        v-validate="'validEmailAttachment:doc,docx,pdf,jpg,jpeg,png,svg|size:2048'" name="attachment"
                        id="attachment" />

                      <img v-show="template_data.attachment.includes('data:image')"
                        :src="template_data.attachment ? template_data.attachment : '../assets/images/banner_image.png'"
                        style="width:100px;height:100px;" />

                      <div v-show="!template_data.attachment.includes('data:image')"></div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="48.306" height="47.478" viewBox="0 0 48.306 47.478">
                        <path data-name="Path 142"
                          d="m4 38.108 13.035-13.035a5.685 5.685 0 0 1 8.038 0l13.035 13.035m-5.685-5.685 4.508-4.508a5.685 5.685 0 0 1 8.038 0l4.508 4.508M32.424 15.369h.028M9.685 49.478h34.108a5.685 5.685 0 0 0 5.685-5.685V9.685A5.685 5.685 0 0 0 43.793 4H9.685A5.685 5.685 0 0 0 4 9.685v34.108a5.685 5.685 0 0 0 5.685 5.685z"
                          transform="translate(-2.586 -3)"
                          style="fill: none;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px;" />
                      </svg>
                      <p v-show="template_data.attachment == ''">
                        Upload / drop your signature here
                      </p>
                    </div>
                  </div>

                  <p class="pt-2" v-show="attached_data">
                    <img src="../../assets/images/docs-svgrepo-com.svg" width="20" class="mr_10" />{{ fileattached }}
                  </p>
                  <small class="error text-danger" v-show="errors.has('sendEmailTemplateForm.attachment')">
                    {{ errors.first("sendEmailTemplateForm.attachment") }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt_24">
            <div class="col-sm-12 mob-btn">
              <a href="javascript:void(0);" class="btn btn_primary" data-bs-toggle="modal"
                data-bs-target="#schedulesend">Schedule Send</a>
              <button type="submit" class="btn btn_primary ml_24 ml-md-10" id="sendNotification">
                Send Notification
              </button>
              <a href="javascript:void(0);" class="btn btn_outline ml_24 ml-md-10" @click="cancelEvent">Cancel</a>
            </div>
          </div>
        </form>

        <div class="client_btn_group mt_24">
          <div class="row my_10 d-flex justify-content-end align-items-center">
            <div class="col-md-12 text-end">
              <!-- Modal -->
              <div class="modal user_box_up fade" id="schedulesend" tabindex="-1" aria-labelledby="schedulesendLabel"
                aria-hidden="true" data-bs-backdrop="false">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="schedulesendLabel">
                        Schedule Send Notification
                      </h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <!-- <label for="schedulesend" class="form-label"> Schedule Send</label> -->
                      <!-- <input type="date"  class="form-control" id="schedulesend"> -->
                      <div class="input-group bsdatepicker">
                        <!-- <date-picker
                          placeholder="MM DD, YYYY"
                          v-model="template_data.scheduledate"
                          ref="dp2"
                          :config="{ format: 'MM DD, YYYY' }"
                          calendar-class="rounded"                       
                        >
                        </date-picker> -->

                        <datepicker placeholder="MM DD, YYYY" v-model="template_data.scheduledate"
                          class="w-100 newdatepker" ref="dp2" :disabledDates="disabledPastDates" :format="'MMM d, yyyy'">
                        </datepicker>

                        <div></div>
                        <div class="input-group-prepend dpicker">
                          <button class="btn btn-outline-primary" type="button" @click="showDatePicker">
                            <img src="../../assets/admin/images/calendar.svg" alt="img" />
                          </button>
                        </div>
                      </div>
                      <div class="form_size_box form-group mt_24">
                        <v-select :options="[
                          '00',
                          '01',
                          '02',
                          '03',
                          '04',
                          '05',
                          '06',
                          '07',
                          '08',
                          '09',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                          '17',
                          '18',
                          '19',
                          '20',
                          '21',
                          '22',
                          '23',
                        ]" label="hours" placeholder="Select hours" v-model="template_data.selectedHours" />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn_outline close-sch-popup" data-bs-dismiss="modal" id="hidemodal"
                        @click="closePopup()">
                        Cancel
                      </button>
                      <button type="button" class="btn btn_primary" @click="sendNotification" :disabled="!isComplete">
                        Schedule
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Modal -->
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- </div>
        </div>
    </div> -->
  </div>
</template>
<script>
import Vue from "vue";
import datePicker from "vue-bootstrap-datetimepicker";
import "bootstrap/dist/css/bootstrap.css";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import "vue-select/dist/vue-select.css";
import ClassicEditor from "ckeditor5-build-classic-image";
import $ from "jquery";
import commonFunction from "../../mixin/commonFunction";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  name: "send_notification",
  components: { Datepicker, VPagination, SortIcons },
  mixins: [commonFunction],
  data() {
    return {
      isLoader: false,
      isCheckAll: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "undo",
            "redo",
          ],
        },
        // The configuration of the editor.
      },
      client_list: [],
      user_email_list: [],
      email_template_list: [],
      template_data: {
        id: "",
        template_name: "",
        sender_name: "",
        subject: "",
        content: "",
        customer_id: "",
        cc: "",
        email_template_id: "",
        email_template_subject: "",
        editorData: "",
        selectedUser: [],
        attachment: "",
        selectedHours: '',
        scheduledate: '',
      },
      uploaderror: false,
      uploadErrorMessage: "",
      fileattached: "",
      attached_data: false,
      disabledPastDates: {
        to: new Date(Date.now() - 8640000),
      },
      user_email_error: "",
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        clientId : null,
        regionBy:null
      },
      regionList: [],
      data_length: 0,
      clearButton: true,
      isSearchAction: false,
    };
  },
  computed: {
    isComplete() {
      return (
        this.template_data.selectedHours && this.template_data.scheduledate
      );
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        startIndex +
        "–" +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getTemplates();
    },
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      clientId: null,
      regionBy:null
    };
    this.getClients();
    if (this.$route.query.successMsg) {
      this.$toast.success(this.$route.query.successMsg, {
        position: "top-right",
        duration: 5000,
      });
      window.history.replaceState(null, null, window.location.pathname);
    }
    if (this.$route.query.errorMsg) {
      this.$toast.error(this.$route.query.errorMsg, {
        position: "top-right",
        duration: 5000,
      });
      window.history.replaceState(null, null, window.location.pathname);
    }
  },
  methods: {
    onPageChange(page) {
      console.log("page", page);
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getTemplates();
    },
    getUsersBySearch(selectClient = null) {
      this.isSearchAction = true;
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getTemplates(selectClient);
    },
    getClients() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/active-clients-access",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          // console.log(decodedData);
          decodedData.allClients.forEach((element) => {
            this.client_list.push({
              id: element.u_id,
              name: element.company_name,
            });
          });
        })
        .catch(({ response }) => {
          if (response.data.type == false) {
            _this.tokenExpired();
          }
        });
    },
    sendEmailTemplate() {
      var _this = this;
      _this.isSearchAction = false;
      /*if(_this.template_data.attachment === "data:application/pdf;base64"){
        _this.template_data.attachment = "";
      }*/
     
      if (localStorage.getItem("userToken")) {
        if (_this.template_data.scheduledate) {
          _this.template_data.scheduledate = moment(
            _this.template_data.scheduledate
          ).format("MM/DD/YYYY");
        }

        _this.$validator
          .validateAll("sendEmailTemplateForm")
          .then((isValid) => {
            if (isValid) {
               _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
              if (_this.user_email_list == "") {
                _this.user_email_error =
                  "The client doesn't have any user. Please select another client.";
                return false;
              } else {
                _this.user_email_error = "";
              }
              let config = {
                method: "post",
                url:
                  process.env.VUE_APP_API_URL + "/api/send-email-notification",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: this.encodeAPIRequestWithImages(_this.template_data),
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  document.getElementsByClassName("close-sch-popup")[0].click();
                  _this.cancelEvent();
                  _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 5000,
                  });

                })
                .catch(({ response }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (response.data.type == false) {
                    _this.tokenExpired();
                  } else {
                    // let r = this.$router.resolve({
                    //   name: "send_notification", // put your route information in
                    //   query: { errorMsg: response.data.error }
                    // });
                    // window.location.assign(r.href)
                    document.getElementsByClassName("close-sch-popup")[0].click();
                    _this.cancelEvent();
                    _this.$toast.success(response.data.error, {
                      position: "top-right",
                      duration: 5000,
                    });


                  }
                });
            } else {
              console.log("Error In Validation", _this.errors);
              // _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            }
          });
      } else {
        _this.tokenExpired();
      }
    },
    getTemplates: function (selectClient = null) {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      if (selectClient == "clientchange"){
            _this.listConfig.regionBy = null;
            _this.listConfig.search_string = null;
            _this.listConfig.limit = _this.itemsPerPage = 10;
      }
      _this.user_email_error = "";
      _this.listConfig.clientId = _this.template_data.customer_id;
      let config = {
        method: "post",
        url:
          process.env.VUE_APP_API_URL +
          "/api/get-client-template",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedData = _this.decodeAPIResponse(data.data);
          _this.isCheckAll = false;
          _this.template_data.selectedUser = [];
          _this.template_data.editorData = "";
          _this.template_data.email_template_subject = "";
          _this.template_data.email_template_id = "";
          _this.email_template_list = [];
          _this.user_email_list = [];
          _this.email_template_list = decodedData["templates"];
          _this.user_email_list = decodedData["userList"];
          // _this.listConfig.regionBy = null;
          _this.regionList = decodedData["clientRegions"].map((region) => ({
              id: region,
              name: region, 
          }));
          _this.data_length = decodedData.userCounts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          if (response.status == 401) {
            _this.tokenExpired();
          }
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        });
    },
    getTemplateDetails: function () {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      if (_this.template_data.email_template_id != null) {
        let config = {
          method: "get",
          url:
            process.env.VUE_APP_API_URL +
            "/api/get-email-tempate-details/" +
            _this.template_data.email_template_id.id,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedData = _this.decodeAPIResponse(data.data);
            _this.template_data.editorData = "";
            var newData = decodedData["templatesdetails"];
            _this.template_data.editorData = newData["content"];
            _this.template_data.email_template_subject = newData["subject"];
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          });
      } else {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
      }
    },
    userAllcheck: function () {
      if (this.isCheckAll == false) {
        const selected = this.user_email_list.map((u) => u.id);
        this.template_data.selectedUser = selected;
      } else {
        this.template_data.selectedUser = [];
      }
    },
    onFileChange(e, file_for) {
      var files = e.target.files || e.dataTransfer.files;
      var filetype = e.target.files[0].name.split(".").pop();
      var arrAllowedFileTypes = [
        "doc",
        "docx",
        "pdf",
        "jpg",
        "jpeg",
        "png",
        "svg",
      ];
      if (!arrAllowedFileTypes.includes(filetype)) {
        this.uploaderror = true;
        this.fileattached = "";
        this.attached_data = false;
        this.template_data.attachment = "";
        this.uploadErrorMessage = "File should be in pdf, text, excel or image format.";
        return false;
      }
      if (e.target.files[0].size > 2000000) {
        this.uploaderror = true;
        this.uploadErrorMessage = "File should not be greater than 2MB.";
        return false;
      } else {
        this.uploaderror = false;
      }
      if (!files.length) return;
      $(".file-upload img").css("margin", "0 auto");
      if (filetype == "png" || filetype == "jpg" || filetype == "jpeg" || filetype == "svg") {
        this.fileattached = "";
        this.attached_data = false;
        $(".file-upload svg").css("display", "none");
        $(".file-upload p").css("display", "none");
        $(".file-upload img").css("display", "block");
      } else {
        this.attached_data = true;
        this.fileattached = e.target.files[0].name;
        $(".file-upload svg").css("display", "block");
        $(".file-upload p").css("display", "block");
        $(".file-upload img").css("display", "none");
      }
      this.createImage(files[0], file_for);
    },
    createImage(file, file_for) {
      var reader = new FileReader();

      reader.onload = (e) => {
        if (file_for == "attachment") {
          this.template_data.attachment = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    sendNotification: function () {
      // $("#hidemodal").trigger("click");
      $('#schedulesend').hide();
      if (!this.template_data.customer_id) {
        document.getElementById("hidemodal").click();
        this.$toast.error("The client field is required", {
          position: "top-right",
          duration: 5000,
        });
      } else if (this.template_data.selectedUser == "") {
        document.getElementById("hidemodal").click();
        this.$toast.error("The user email field is required", {
          position: "top-right",
          duration: 5000,
        });
      } else if (this.template_data.email_template_subject == "") {
        document.getElementById("hidemodal").click();
        this.$toast.error("The subject field is required", {
          position: "top-right",
          duration: 5000,
        });
      } else if (this.template_data.editorData == "") {
        document.getElementById("hidemodal").click();
        this.$toast.error("The content field is required", {
          position: "top-right",
          duration: 5000,
        });
      } else {
        $("#sendNotification").trigger("click");
      }
    },
    cancelEvent: function () {
      this.listConfig.clientId = null;
      this.data_length = 0;
      this.listConfig.search_string = null;
      this.listConfig.regionBy = null;
      this.template_data.id = "";
      this.template_data.template_name = "";
      this.template_data.sender_name = "";
      this.template_data.content = "";
      this.template_data.email_template_subject = "";
      this.template_data.customer_id = "";
      this.template_data.email_template_id = "";
      this.template_data.editorData = "";
      this.template_data.selectedUser = [];
      this.template_data.attachment = "";
      this.user_email_list = [];
      this.email_template_list = [];
      this.isCheckAll = false;
      this.$refs.attachmentref.value = null;
      this.attached_data = false;
      this.fileattached = "";
      $(".file-upload svg").css("display", "block");
      this.$validator.reset();
    },
    closePopup: function () {
      this.template_data.scheduledate = "";
      this.template_data.selectedHours = "";
    },
    showDatePicker() {
      this.$refs.dp2.showCalendar();
    }
  },
};

Vue.use(datePicker);
</script>